import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable()
export class ApiEndpointInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let urlReq: any = "";
    if (
      req.url.includes("prontos-temporario.s3") ||
      req.url.includes("prontos-temporario-dev-hmg.s3") ||
      req.url.includes("prontos-temporario-dev-hmg-01.s3") ||
      req.url.includes("prontos-manuais") ||
      req.url.includes("s3.amazonaws.com") ||
      req.url.includes("amazonaws.com")
    ) {
      urlReq = req.clone({ responseType: "arraybuffer" });
      urlReq.headers.lazyUpdate = [];
      return next.handle(urlReq);
    } else {
      if (
        req.url.includes("videos") ||
        req.url.includes("assets/i18n") ||
        req.url.includes("http://") ||
        req.url.includes("https://")
      ) {
        urlReq = req.clone({ url: req.url });
      } else if (req.url.includes("report-api")) {
        urlReq = req.clone({ url: this.createURL(environment.reportEndpointPath, req.url) });
      } else if (req.url.includes("portal-upload")) {
        urlReq = req.clone({
          url: this.createURL(environment.uploadEndpointPath, req.url),
          withCredentials: true,
        });
      } else if (req.url.includes("teste-api")) {
        urlReq = req.clone({ url: this.createURL(environment.testeEndpointPath, req.url) });
      } else if (req.url.includes("geral-api")) {
        urlReq = req.clone({ url: this.createURL(environment.geralEndpointPath, req.url) });
      } else {
        urlReq = req.clone({ url: this.createURL(environment.apiEndpointPath, req.url) });
      }
    }
    return next.handle(urlReq);
  }

  createURL(serverPath: string, reqURL: string): string {
    serverPath = serverPath.endsWith("/") ? serverPath.slice(0, -1) : serverPath;
    reqURL = reqURL.startsWith("/") ? reqURL : `/${reqURL}`;
    return serverPath + reqURL;
  }
}
