<div class="overlay-mobile hide-xl" [class.show]="sidebarOpen" (click)="closeSidebar()"></div>
<div
  class="layout-sidebar"
  [class.open]="sidebarOpen"
  [ngStyle]="{ overflow: app.sidebarActive ? 'hidden' : 'auto' }"
  [ngClass]="{ 'layout-sidebar-dark': app.darkMenu }"
  (click)="app.onSidebarClick($event)"
>
  <a routerLink="/">
    <img class="logomarca" [src]="logomarca" alt="Ícone prontos" />
  </a>
  <div class="layout-tabmenu">
    <div class="close-content hide-xl" (click)="closeSidebar()">
      <button class="btn">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <ul class="layout-tabmenu-nav">
      @for (item of sidebarItems; track item; let index = $index) {
      <li
        *appHasPermission="item.permission"
        [ngClass]="{ 'active-item': app.activeTabIndex === index }"
        class="li-menu"
        [attr.data-tutorial]="'menu-' + item.description"
      >
        <a
          [pTooltip]="item.tooltip | translate"
          [routerLink]="item.router ? [item.router] : []"
          class="ripplelink tabmenuitem-link a-menu"
          (click)="clickMenu($event, index, item.logout)"
          [ngClass]="{ 'a-active-item': app.activeTabIndex === index }"
        >
          <img class="icon" [src]="'/assets/images/sidebar/' + item.icon" [alt]="item.tooltip" />
          @if (item.badge | async; as b) {
          <span class="badge"> {{ b }} </span>
          }
        </a>
        <div class="layout-tabmenu-tooltip">
          <div class="layout-tabmenu-tooltip-arrow"></div>
          <div class="layout-tabmenu-tooltip-text">{{ item.label }}</div>
        </div>
      </li>
      }
    </ul>
    <div class="layout-tabmenu-contents">
      @for (item of sidebarItems; track item; let index = $index) {
      <div
        class="layout-tabmenu-content"
        [ngClass]="{ 'layout-tabmenu-content-active': app.activeTabIndex === index }"
      >
        <app-sidebar-tab-content>
          <app-menu [items]="item.children"></app-menu>
        </app-sidebar-tab-content>
      </div>
      }
    </div>
  </div>
</div>

@if (isSubMenuVisible) {
<div class="layout-sidebar-submenu">
  @for (submenu of submenus; track submenu; let index = $index) {
  <div
    class="item-submenu"
    *appHasPermission="submenu.permission"
    [ngClass]="{ 'item-submenu-active': submenuIndexActive === index }"
    (click)="clickSubMenu(submenu.router, index)"
  >
    <label class="label-item-submenu" translate>{{ submenu.label }}</label>
  </div>
  }
</div>
}
