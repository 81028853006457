import { Component } from '@angular/core';

/**
 * Componente para o conteúdo das abas laterais do sistema.
 */
@Component({
  selector: 'app-sidebar-tab-content',
  templateUrl: 'sidebar-tab-content.component.html',
  styleUrls: ['./sidebar-tab-content.component.css']
})
export class SidebarTabContentComponent { }
