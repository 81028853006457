import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../session-storage.service';
import { SessionStorageKeys } from '../../enums/session-storage-keys.enum';

@Injectable({
  providedIn: 'root'
})
export class CurrentUrlGuard  {
  routerByNavigationBrowser: string;

  constructor(
    private sessionStorageService: SessionStorageService,
    private location: Location
  ) {
    // Fica escutando sempre que o usuário clica na <- de voltar no navegador
    this.location.subscribe(popstateEvent => {
      // Verifica se o usuário está voltando via browser
      if (popstateEvent.pop) {
        // Verifica se já voltou todas as páginas possivel
        if (popstateEvent.url != '/redirect') {
          // Adiciona a variavel o path que está sendo retornado
          this.routerByNavigationBrowser = popstateEvent.url.replace('/', '');
        }
      }
    });
  }


  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const currentUrl = this.sessionStorageService.getItem<string>(SessionStorageKeys.CURRENT_URL);
    const nextUrl = state.url.replace('/', '');

    if (this.isLoginPage(currentUrl)) {
      return true;
    }
    const isAutorizado: boolean = (currentUrl || this.routerByNavigationBrowser) === nextUrl;
    return isAutorizado;

  }

  private isLoginPage(currentUrl: string) {
    return currentUrl == undefined;
  }
}
