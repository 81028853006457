import { Component, OnInit } from "@angular/core";
import { ContratoService } from "./shared/api-services/contrato.service";
import { HierarquiaService } from "./shared/api-services/hierarquia.service";
import { IdiomaService } from "./shared/services/idioma.service";
import { KeycloakService } from "keycloak-angular";
import { UsuarioDataService } from "./shared/services/usuario-data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "portal-web";

  constructor(
    private contratoService: ContratoService,
    private hierarquiaService: HierarquiaService,
    private idiomaService: IdiomaService,
    private keyCloakService: KeycloakService,
    private usuarioDataService: UsuarioDataService
  ) {}

  ngOnInit() {
    const isLogged = this.keyCloakService.isLoggedIn();
    if (isLogged) {
      this.idiomaService.configurarServicoTraducao();
      this.contratoService.atualizarContratoUsuarioLogadoState();
      if ((window as any)?.newrelic?.setUser) {
        (window as any)?.newrelic?.setUser(this.usuarioDataService.getLoginName());
      }
    }
  }
}
