<div class="editar-container" [ngClass]="{ visible: show }">
  <ng-container *ngTemplateOutlet="userTemplate"></ng-container>
</div>

<p-overlayPanel #op [style]="{ width: '450px' }">
  <ng-container *ngTemplateOutlet="userTemplate"></ng-container>
</p-overlayPanel>

<ng-template #userTemplate>
  <div class="user-content">
    <span class="user-initials">
      {{ nmIniciais }}
    </span>
    <span class="user-completo"> {{ nmCompleto }} </span>
  </div>
  <div class="editar-content">
    @for (item of itensMenu; track item) {
    <div class="editar-item">
      @if(loadingDados) {
      <div class="editar-item-loading">
        <p-skeleton width="20px" height="25px" />
        <p-skeleton width="200px" height="25px" />
        <p-skeleton width="20px" height="25px" />
      </div>

      }@else {
      <button type="button" class="editar-button" (click)="handleOnClickEdit()">
        <img class="icon" [src]="item.icon" />
        <span class="editar-label" translate> {{ item.label }} </span>
        <img class="edit-icon" src="assets/images/sidebar/edit.svg" [pTooltip]="item.tooltip | translate" />
      </button>
      }
    </div>
    }
  </div>
  <div class="editar-footer">
    <div class="footer-item">
      <button type="button" class="button-sair" (click)="logout()">
        <img class="icon" src="assets/images/sidebar/sair.svg" />
        <span translate> editarDados.sair </span>
      </button>
    </div>
  </div>
</ng-template>

<p-dialog
  [header]="'editarDados.tituloPopup' | translate"
  [(visible)]="showPopupEdicao"
  modal="true"
  [style]="{ width: '90%', 'max-width': '480px' }"
>
  <div class="dialog-content">
    <p class="instrucoes" translate [innerHtml]="'editarDados.instrucoes' | translate"></p>
    <form #f="ngForm" class="p-grid" (submit)="saveEdit(f)">
      <div class="p-col-12 mb-3">
        <label for="dsNome" class="label-input" translate> editarDados.dsNome </label>
        <input
          ngModel
          name="dsNome"
          type="text"
          pInputText
          maxlength="300"
          required
          [placeholder]="'editarDados.editarNomePlaceholder' | translate"
        />
      </div>
      <div class="p-col-12 mb-3">
        <label for="dsEmail" class="label-input" translate> editarDados.dsEmail </label>
        <input
          ngModel
          name="dsEmail"
          type="text"
          pattern="[\w-\.]+@([\w-]+\.)+[\w-]{2,4}"
          pInputText
          maxlength="200"
          [placeholder]="'editarDados.editarEmailPlaceholder' | translate"
        />
      </div>
      <div class="p-col-12 mb-3">
        <label for="dsCelular" class="label-input" translate> editarDados.dsCelular </label>
        <p-inputMask
          ngModel
          name="dsCelular"
          mask="99 99999-9999"
          [placeholder]="'editarDados.editarTelefonePlaceholder' | translate"
        />
      </div>
      <div class="p-col-12">
        <button
          pButton
          class="botao-salvar"
          type="submit"
          [label]="'botaoSalvar' | translate"
          [disabled]="f.invalid"
          [loading]="loadingEdit"
        ></button>
      </div>
    </form>
  </div>
</p-dialog>
