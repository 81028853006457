import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthComponent } from "./auth/auth.component";
import { InitAppComponent } from "./init-app.component";
import { LayoutComponent } from "./layout/layout.component";
import { TermoConsentimentoComponent } from "./pages/termo-consentimento/termo-consentimento/termo-consentimento.component";
import { ConfigLoadedGuard } from "./shared/services/guards/configs-loaded.guard";
import { CurrentUrlGuard } from "./shared/services/guards/current-url.guard";
import { NaoOperadorGuard } from "./shared/services/guards/nao-operador.guard";
import { TermoConsentimentoGuard } from "./shared/services/guards/termo-consentimento.guard";
import { AuthKeyCloakGuard } from "./shared/services/guards/auth-keycloak.guard";

export const routes: Routes = [
  { path: "login", component: AuthComponent },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthKeyCloakGuard],
    canActivateChild: [NaoOperadorGuard, CurrentUrlGuard],
    children: [
      {
        path: "termo-consentimento",
        component: TermoConsentimentoComponent,
        loadChildren: () =>
          import("./pages/termo-consentimento/termo-consentimento.module").then(
            (m) => m.TermoConsentimentoModule
          ),
      },
      {
        path: "",
        component: InitAppComponent,
        canActivateChild: [ConfigLoadedGuard, TermoConsentimentoGuard],
        loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
      },
    ],
  },
  { path: "**", redirectTo: "redirect", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
