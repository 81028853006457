import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";

import { Observable } from "rxjs";

import { UsuarioDataService } from "../services/usuario-data.service";

@Injectable()
export class TokenWafInterceptor implements HttpInterceptor {
  constructor(private usuarioDataService: UsuarioDataService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.includes("prontos-temporario.s3") ||
      req.url.includes("prontos-temporario-dev-hmg.s3") ||
      req.url.includes("s3.amazonaws.com") ||
      req.url.includes("amazonaws.com")
    ) {
      return next.handle(req);
    }

    const urlReq = req.clone({
      headers: req.headers.append("X-PRONTOS-WAF", encodeURI(this.usuarioDataService.getLoginName())),
    });
    return next.handle(urlReq);
  }
}
