<div class="topbar">
  <div class="topbar-container">
    <div class="logo-container">
      <a routerLink="/">
        <img class="logomarca" [src]="logomarca" alt="Ícone prontos" />
      </a>
    </div>
    <div class="cliente-container">
      {{ nmCliente }}
    </div>
    <div class="user-container">
      <button type="button" class="user-initials" (click)="editarPopup.toggle()">
        {{ nmInicais }}
      </button>
      <div class="user-content">
        <span class="user-name"> {{ nmUsuario }}</span>
        <button pButton type="button" class="user-button" (click)="editarPopup.toggle($event)">
          <span translate> tituloSidebar.mostrarMais </span>
          <em class="pi pi-angle-down"></em>
        </button>
      </div>
      <div class="burguer-icon-content">
        @if(editarPopup.show) {
        <button class="btn" (click)="editarPopup.toggle()">
          <span class="material-icons"> close </span>
        </button>
        } @else {
        <button class="btn" (click)="toggleSidebar()">
          <span class="material-icons"> menu </span>
        </button>
        }
      </div>
    </div>
    <app-editar-dados-popup #editarPopup [nmIniciais]="nmInicais" [nmCompleto]="nmCliente" />
  </div>
</div>
