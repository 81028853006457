import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ViewModelError } from "../errors/view-model.error";
import { ErroTO } from "../tos/erro.to";
import { NotificationService } from "./notification.service";
import { AuthService } from "./auth.service";

/**
 * Classe que faz o tratamento de erros global do sistema. Qualquer exceção gerada no sistema que não for tratada, passa
 * por esta classe. Caso o erro seja proveniente do backend, é feito o tratamento apropriado de acordo com o status do
 * erro.
 */
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private injector: Injector,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  /**
   * Retorna o serviço de autenticação a partir do injector do Angular.
   *
   * @return a instância de AuthenticationService provida na aplicação
   */
  // private get authenticationService(): PMVAuthenticationService {
  //   return this.injector.get(PMVAuthenticationService);
  // }

  /**
   * Faz o tratamento do erro lançado.
   *
   * @param error erro capturado pelo ErrorHandler
   */
  handleError(error: any): void {
    error = error.rejection || error;
    if (this.isFrontendError(error)) {
      this.handleFrontEndError(error);
    } else if (this.isBackendError(error)) {
      this.handleBackendError(error);
    } else {
      throw error;
    }
  }

  isFrontendError(error: any): boolean {
    return error instanceof ViewModelError;
  }

  isBackendError(error: any): boolean {
    return error instanceof HttpErrorResponse || (error.error && error.error.erro);
  }

  handleFrontEndError(error: ViewModelError): void {
    this.notificationService.notifyError(error.message);
  }

  /**
   * Faz o tratamento de um erro proveniente da comunicação com o backend do sistema.
   *
   * @param error erro de comunicação Http
   */
  handleBackendError(error: any): void {
    if (error.status === 0) {
      this.notificationService.notifyError("Não foi possível se conectar ao servidor.");
    } else {
      const erroTO: ErroTO = error.error;
      const message =
        (erroTO && erroTO[0] ? erroTO[0].dsErro : null) ||
        (erroTO ? erroTO.dsErro : null) ||
        this.getDefaultMessage(error.status) ||
        "Erro inesperado";
      const handleAccept = error.status === 403 ? () => this.authService.logout() : () => {};
      this.notificationService.notifyError(message, undefined, handleAccept);
    }
  }

  /**
   * Recupera a mensagem padrão para os determinados status HTTP.
   *
   * @param status status HTTP presente na requisição
   * @return a mensagem associada ao status HTTP
   */
  getDefaultMessage(status: number): string {
    switch (status) {
      case 401:
        return "Usuário não autorizado."; //Remover mensagens
      case 403:
        return this.getMensagemTraduzida("msgForbidden");
      case 500:
        return "Erro no servidor.";
    }
  }

  getMensagemTraduzida(chave: string): string {
    return this.translateService.instant(chave);
  }
}
