<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
  <li [ngClass]="{'active-menuitem': isActive(i)}" *ngIf="child.visible === false ? false : true">
      <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" class="ripplelink" *ngIf="!child.routerLink" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
          (mouseenter)="hover=true" (mouseleave)="hover=false">
          <em style="font-size: 1.7em;" *ngIf="child.material == 'true'" class="material-icons">{{child.icon}}</em>
          <em style="font-size: 1.7em;" *ngIf="child.material == 'false'" class={{child.icon}}></em>
          <span>{{child.label}}</span>
          <span class="ink" *ngIf="hover"></span>
          <em class="material-icons" *ngIf="child.items">keyboard_arrow_down</em>
      </a>

      <a (click)="itemClick($event,child,i)" class="ripplelink" *ngIf="child.routerLink"
          [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
          (mouseenter)="hover=true" (mouseleave)="hover=false">
          <em style="font-size: 1.7em;" *ngIf="child.material == 'true'" class="material-icons">{{child.icon}}</em>
          <em style="font-size: 1.7em;" *ngIf="child.material == 'false'" class={{child.icon}}></em>
          <span>{{child.label}}</span>
          <span class="ink" *ngIf="hover"></span>
          <em class="material-icons" *ngIf="child.items">keyboard_arrow_down</em>
      </a>
      <ul app-submenu [item]="child" *ngIf="child.items" [@children]="isActive(i) ? 'visible' : 'hidden'" [visible]="isActive(i)"></ul>
  </li>
</ng-template>
