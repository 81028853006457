import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";

import { initializer } from "./app-init";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
import { LayoutModule } from "./layout/layout.module";
import { PagesModule } from "./pages/pages.module";
import { ApiEndpointInterceptor } from "./shared/interceptors/api-endpoint.interceptor";
import { UrlLocaleInterceptor } from "./shared/interceptors/url-locale.interceptor";
import { TokenWafInterceptor } from "./shared/interceptors/token-waf.interceptor";
import { CustomErrorHandler } from "./shared/services/error-handler";
import { SharedModule } from "./shared/shared.module";
import { CookieService } from "ngx-cookie-service";
import { ConfirmationService } from "primeng/api";
import { AuthenticatedInterceptor } from "./shared/interceptors/authenticated.interceptor";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import localeAr from "@angular/common/locales/ar";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeHi from "@angular/common/locales/hi";
import localeUr from "@angular/common/locales/ur";
import localeDe from "@angular/common/locales/de";
import localeId from "@angular/common/locales/id";
import localeZh from "@angular/common/locales/zh";
import { InitAppComponent } from "./init-app.component";
import { IdiomaService } from "./shared/services/idioma.service";
import { CustomTranslateLoader } from "./shared/services/custom-translate-loader.service";

registerLocaleData(localePt);
registerLocaleData(localeAr);
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeHi);
registerLocaleData(localeUr);
registerLocaleData(localeDe);
registerLocaleData(localeId);
registerLocaleData(localeZh);

@NgModule({
  declarations: [AppComponent, InitAppComponent],
  bootstrap: [AppComponent],
  imports: [
    KeycloakAngularModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    LayoutModule,
    AuthModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticatedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiEndpointInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenWafInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UrlLocaleInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: APP_INITIALIZER, useFactory: initializer, multi: true, deps: [KeycloakService] },
    {
      provide: LOCALE_ID,
      deps: [IdiomaService],
      useFactory: (idiomaService: IdiomaService) => idiomaService.getBaseLang(),
    },
    CookieService,
    ConfirmationService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
