<router-outlet></router-outlet>
<app-dialog-notification></app-dialog-notification>
<p-toast [style]="{ marginTop: '80px' }" position="top-right"></p-toast>
<p-confirmDialog #cd [header]="'tituloConfirmaExclusao' | translate">
  <p-footer>
    <button
      pButton
      class="botao-branco"
      type="button"
      [label]="'botaoCancelar' | translate"
      (click)="cd.reject()"
    ></button>
    <button
      pButton
      class="botao-excluir"
      type="button"
      [label]="'botaoExcluir' | translate"
      (click)="cd.accept()"
    ></button>
  </p-footer>
</p-confirmDialog>
