import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Roles } from "../../enums/roles.enum";
import { UsuarioService } from "../../api-services/usuario.service";
import { RouterService } from "../router-service.service";

@Injectable({
  providedIn: "root",
})
export class NaoOperadorGuard {
  constructor(
    protected keycloakAngularService: KeycloakService,
    private usuarioService: UsuarioService,
    private routerService: RouterService
  ) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((res, rej) => {
      this.usuarioService.getTiposUsuario(false).subscribe(
        (items) => {
          const userRoles = this.usuarioService.getRoles();
          const allRoles = items.map((i) => i.value);
          const activeRoles = allRoles.filter((role) => userRoles.includes(role));
          if (activeRoles.length < 2 && activeRoles.includes(Roles.OPERADOR)) {
            this.routerService.toTesteProntidao();
          } else {
            res(true);
          }
        },
        (err) => {
          res(false);
          throw err;
        }
      );
    });
  }
}
