import { environment } from "src/environments/environment";
import { appendScript } from "./shared/util/appendScript.util";
import { ClienteUtil } from "./shared/util/cliente.util";

export const initNewRelic = () => {
  const BLOCK_CLIENTS = [ClienteUtil.isDev()];
  const isBlocked = BLOCK_CLIENTS.includes(environment.cliente as any);
  if (!isBlocked) {
    appendScript(`assets/new-relic.js`, "head");
  }
};
