import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";

import { Observable } from "rxjs";
import { KeycloakService } from "keycloak-angular";

@Injectable()
export class AuthenticatedInterceptor implements HttpInterceptor {
  constructor(private keyCloak: KeycloakService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAuthenticated = this.keyCloak.isLoggedIn();
    if (isAuthenticated) {
      return next.handle(req);
    }
    this.keyCloak.login();
  }
}
