import { Component, Input } from '@angular/core';

import { LayoutComponent } from '../layout.component';

/**
 * Componente de menu do sistema.
 */
@Component({
  selector: 'app-menu',
  templateUrl: 'menu.component.html',
})
export class MenuComponent {

  @Input() items: any[];

  constructor(
    public app: LayoutComponent,
  ) { }

}
