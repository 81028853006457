import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";

import { KeycloakService } from "keycloak-angular";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { NovidadesService } from "src/app/shared/api-services/novidades.service";
import { UsuarioService } from "src/app/shared/api-services/usuario.service";
import { TipoProdutoEnum } from "src/app/shared/enums/tipo-produto.enum";
import { RouterService } from "src/app/shared/services/router-service.service";

import { LayoutComponent } from "../layout.component";
import { SidebarService } from "./sidebar.service";
import { RoleService } from "src/app/shared/services/role.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { ResponseContratoHierarquiaTO } from "src/app/shared/tos/response-contrato-hierarquia.to";
import { SimNaoEnum } from "src/app/shared/enums/sim-nao-enum";

/**
 * Componente da barra lateral do sistema.
 */
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  menuCustomizacao: any[];
  menuAdministrativo: any[];
  menuGestaoSaude: any[];
  menuEstoque: any[];

  sidebarItems: any[];

  submenus: any[];

  isSubMenuVisible: boolean;
  submenuIndexActive: number;
  menuIndexActive: number;

  logomarca: string;

  // showConfirmPaymentModal: boolean;

  @Output() submenuActiveChange = new EventEmitter<boolean>();

  constructor(
    public app: LayoutComponent,
    private router: Router,
    private translateService: TranslateService,
    private keyCloakService: KeycloakService,
    private novidadeService: NovidadesService,
    private sidebarService: SidebarService,
    private contratoService: ContratoService,
    private usuarioService: UsuarioService,
    private routerService: RouterService,
    private roleService: RoleService
  ) {}

  ngOnInit() {
    this.contratoService.getContrato().subscribe(this.constroiMenus.bind(this));
    this.isSubMenuVisible = false;
    this.emitIsSubmenuActive();
    const isEspanhol = this.translateService.getBrowserLang() === "es";
    this.logomarca = isEspanhol ? "assets/images/logo-listos.png" : "assets/images/prontos-icone-01.png";
  }

  /**
   * Monta o array com todos os menus do sistema, a tela vai ler o conteudo do array
   * sidebarItems.
   */
  constroiMenus({ cdTipoProduto, flExibeTokenLiberacaoApp }: ResponseContratoHierarquiaTO): void {
    const isFlex = cdTipoProduto == TipoProdutoEnum.PRONTOS_FLEX;

    const inicio: string = "/inicio";
    const dashboard: string = "/dashboard";
    const nivel: string = "/nivel-resultado";
    const acao: string = "/acao";
    const parametroReferencia: string = "/parametro-referencia";
    const grupo: string = "/classificacao-grupo";
    const clasificacaoResultado: string = "/classificacao-resultado";
    const regraAcaoSugerida: string = "/regra-acao-sugerida";
    const configuracao: string = "configuracao";
    const usuarios: string = "/usuario";
    const turno: string = "/turno";
    const exportacaoDadosBrutos: string = "/exportacao-dados-brutos";
    const dicas: string = "/dicas";
    const cargo: string = "/cargo";
    const hierarquia: string = "/hierarquia";
    const gerarRelatorios: string = "/relatorios/gerar";
    const informativo: string = "/informativo";
    const validarAcesso: string = "/validacao-acesso";
    const avisoFerias: string = "/aviso-de-ausencia";
    const novidades: string = "/novidades";
    const centralNotificacoes: string = "/central-notificacoes";
    const medicaoServicos: string = "/medicao-servicos";
    const acolhimento: string = "/acolhimento";
    const desvioFadiga: string = "/desvio-fadiga";
    const escala: string = "/escala";
    const checklist: string = "/checklist";
    const suporte: string = "/faq";
    const gestaoTemporaria: string = "/gestao-temporaria";
    const permissoes: string = "/permissoes";
    const perguntas: string = "/perguntas";
    const liberacaoApp: string = "/liberacao-app";
    const acidente: string = "/acidente/usuario/novo";
    const isOperador = this.roleService.isRole(Permissao.OPERADOR);

    this.sidebarItems = [
      {
        description: "dashboard",
        icon: "dashboard.svg",
        tooltip: "tituloSidebar.dashboardTooltip",
        router: inicio,
        permission: ["ALL"],
      },
      {
        icon: "relatorios.svg",
        tooltip: "tituloSidebar.relatorioTooltip",
        submenu: true,
        permission: [
          "RL_ADMINISTRADOR",
          "RL_GESTOR_SME",
          "RL_GESTOR_SME_MEDICAO",
          "RL_GESTOR",
          "RL_MEDICO",
          "RL_SUPERVISOR",
          "RL_GESTOR_CLIENTE",
        ],
        submenus: [
          {
            label: this.getTituloSidebar("tituloSidebar.gerarRelatorios"),
            router: gerarRelatorios,
            permission: ["ALL"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.exportacaoDadosBrutos"),
            router: exportacaoDadosBrutos,
            permission: !isFlex
              ? ["RL_VISUALIZAR_REL_DADOS_BRUTOS"]
              : ["RL_ADMINISTRADOR", "RL_GESTOR_SME", "RL_GESTOR_SME_MEDICAO"],
          },
          {
            label: this.getTituloSidebar("medicaoServicos.titulo"),
            router: medicaoServicos,
            permission: ["RL_ADMINISTRADOR", "RL_GESTOR_SME_MEDICAO"],
          },
        ],
      },
      {
        icon: "usuarios.svg",
        tooltip: "tituloSidebar.usuariosTooltip",
        router: usuarios,
        permission: ["RL_VISUALIZAR_USUARIO"],
      },
      {
        icon: "configuracoes.svg",
        tooltip: "tituloSidebar.configuracaoTooltip",
        permission: [
          "RL_ADMINISTRADOR",
          "RL_GESTOR_SME",
          "RL_GESTOR_SME_MEDICAO",
          "RL_MEDICO",
          "RL_SUPERVISOR",
          "RL_GESTOR_CLIENTE",
          "RL_GESTOR",
        ],
        submenu: true,
        submenus: [
          {
            label: this.getTituloSidebar("tituloSidebar.nivelResultado"),
            router: nivel,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.acao"),
            router: acao,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.classificacaoGrupo"),
            router: grupo,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.classificacaoResultado"),
            router: clasificacaoResultado,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.parametrosDeReferencia"),
            router: parametroReferencia,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.regradeAcaoSugerida"),
            router: regraAcaoSugerida,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.configuracao"),
            router: configuracao,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.turno"),
            router: turno,
            permission: ["RL_VISUALIZAR_TURNO"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.cargo"),
            router: cargo,
            permission: ["RL_VISUALIZAR_CARGO"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.hierarquia"),
            router: hierarquia,
            permission: ["RL_VISUALIZAR_HIERARQUIA"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.validarAcesso"),
            router: validarAcesso,
            permission: ["RL_VISUALIZAR_ACESSO_APP"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.avisoFerias"),
            router: avisoFerias,
            permission: [
              "RL_ADMINISTRADOR",
              "RL_GESTOR_SME",
              "RL_GESTOR_SME_MEDICAO",
              "RL_MEDICO",
              "RL_SUPERVISOR",
              "RL_GESTOR_CLIENTE",
              "RL_GESTOR",
            ],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.informativo"),
            router: informativo,
            permission: [
              "RL_ADMINISTRADOR",
              "RL_GESTOR_SME",
              "RL_GESTOR",
              "RL_MEDICO",
              "RL_SUPERVISOR",
              "RL_SUPERVISOR_CHECKLIST",
              "RL_GESTOR_CLIENTE",
              "RL_GESTOR_SME_MEDICAO",
            ],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.dicas"),
            router: dicas,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.centralNotificacoes"),
            router: centralNotificacoes,
            permission: ["ALL"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.acolhimento"),
            router: acolhimento,
            permission: ["RL_ADMINISTRADOR", "RL_GESTOR_SME", "RL_GESTOR_SME_MEDICAO", "RL_MEDICO"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.desvioFadiga"),
            router: desvioFadiga,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("escala.titulo"),
            router: escala,
            permission: ["RL_MANTER_ESCALA_FOLGA", "RL_MANTER_ESCALA_FOLGA_ITEM"],
          },
          {
            label: this.getTituloSidebar("gestaoTemporaria.titulo"),
            router: gestaoTemporaria,
            permission: ["RL_MANTER_GESTAO_TEMPORARIA"],
          },
          {
            label: this.getTituloSidebar("permissoes.titulo"),
            router: permissoes,
            permission: ["RL_VISUALIZAR_PERMISSAO"],
          },
          {
            label: this.getTituloSidebar("perguntas.titulo"),
            router: perguntas,
            permission: ["RL_ADMINISTRADOR"],
          },
          {
            label: this.getTituloSidebar("liberacaoApp.titulo"),
            router: liberacaoApp,
            permission: flExibeTokenLiberacaoApp == SimNaoEnum.NAO ? [] : ["RL_LIBERACAO_APP_USUARIO"],
          },
          {
            label: this.getTituloSidebar("tituloSidebar.acidente"),
            router: acidente,
            permission: ["RL_ADMINISTRADOR", "RL_GESTOR_SME", "RL_GESTOR_SME_MEDICAO"],
          },
        ],
      },
      {
        icon: "novidades.svg",
        tooltip: "tituloSidebar.novidadesTooltip",
        router: novidades,
        badge: this.novidadeService.getQtdNaoLidas(),
        permission: ["ALL"],
      },
      {
        icon: "checklist.svg",
        tooltip: "tituloSidebar.checklistTooltip",
        router: checklist,
        permission: ["RL_ADMINISTRADOR", "RL_GESTOR_SME", "RL_GESTOR_SME_MEDICAO"],
      },
      {
        icon: "teste.svg",
        tooltip: "centralNotificacoes.flNotificacao",
        cb: () => this.routerService.toTesteProntidao(),
        permission: ["RL_OPERADOR"],
      },
      {
        icon: "ajuda.svg",
        tooltip: "faq.titulo",
        router: suporte,
        permission: isFlex
          ? []
          : [
              "RL_ADMINISTRADOR",
              "RL_GESTOR_SME",
              "RL_GESTOR",
              "RL_MEDICO",
              "RL_SUPERVISOR",
              "RL_SUPERVISOR_CHECKLIST",
              "RL_GESTOR_CLIENTE",
              "RL_GESTOR_SME_MEDICAO",
            ],
      },
      {
        icon: "sair-branco.svg",
        tooltip: "tituloSidebar.sairTooltip",
        logout: true,
        permission: ["ALL"],
      },
    ];
    this.app.activeTabIndex = 0;
    this.sidebarItems = this.hideMenus(cdTipoProduto, this.sidebarItems);
    this.sidebarItems.forEach((item) => {
      if (item.submenus) {
        item.submenus.sort((a, b) => a.label.localeCompare(b.label));
      }
    });
  }

  hideMenus(cdTipoProduto: TipoProdutoEnum, menuItens: any[]) {
    const isEac = cdTipoProduto === TipoProdutoEnum.PRONTOS_EAC;
    if (!isEac) return menuItens;
    const itemsToHide = [
      "/acao",
      "/regra-acao-sugerida",
      "/dicas",
      "/aviso-de-ausencia",
      "/novidades",
      "/central-notificacoes",
      "/desvio-fadiga",
      "/escala",
      "/faq",
      "/gestao-temporaria",
      "/validacao-acesso",
      "/medicao-servicos",
    ];
    return menuItens.map((itemMenu) => {
      if (itemsToHide.includes(itemMenu.router)) {
        itemMenu.permission = [];
      }
      if (itemMenu.submenus) {
        itemMenu.submenus = this.hideMenus(cdTipoProduto, itemMenu.submenus);
      }
      return itemMenu;
    });
  }

  clickMenu($event, index, logout) {
    this.visualizarSubmenu(index);

    this.app.onTabClick($event, index);
    this.emitIsSubmenuActive();

    this.menuIndexActive = index;

    if (logout != null) {
      this.sidebarService.logout();
    }

    if (this.sidebarItems[index] && this.sidebarItems[index].router) {
      this.sidebarService.closeSidebar();
    }

    this.sidebarItems[index] && this.sidebarItems[index].cb && this.sidebarItems[index].cb();
  }

  mouseoverMenu(index: number) {
    // console.log(index);
    this.visualizarSubmenu(index);
  }

  mouseoutrMenu(index: number) {
    this.isSubMenuVisible = false;
  }

  clickSubMenu(route: string, index: number) {
    this.sidebarService.closeSidebar();
    this.router.navigate([route]);
    this.submenuIndexActive = index;
    this.isSubMenuVisible = false;
  }

  visualizarSubmenu(index) {
    if (this.sidebarItems[index].submenu === true) {
      this.submenuIndexActive = -1;
      if (
        this.menuIndexActive === index ||
        !this.menuIndexActive ||
        !this.sidebarItems[this.menuIndexActive].submenu
      ) {
        this.isSubMenuVisible = !this.isSubMenuVisible;
      }
    } else if (
      this.app.activeTabIndex !== undefined &&
      this.sidebarItems[this.app.activeTabIndex].submenu === true
    ) {
      this.isSubMenuVisible = false;
    }

    if (this.isSubMenuVisible) {
      this.submenus = this.sidebarItems[index].submenus;
    }
  }

  get sidebarOpen() {
    return this.sidebarService && this.sidebarService.sidebarOpen;
  }

  closeSidebar() {
    this.isSubMenuVisible = false;
    this.sidebarService.closeSidebar();
    this.emitIsSubmenuActive();
  }

  emitIsSubmenuActive() {
    this.submenuActiveChange.emit(this.isSubMenuVisible);
  }

  getTituloSidebar(chave: string): string {
    return this.translateService.instant(chave);
  }
}
