import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UsuarioTermoConsentimentoService } from "../../api-services/usuario-termo-consentimento.service";
import { RouterService } from "../router-service.service";

@Injectable({
  providedIn: "root",
})
export class TermoConsentimentoGuard {
  constructor(
    private usuarioTermoConsentimentoService: UsuarioTermoConsentimentoService,
    private routerService: RouterService
  ) {}

  canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.usuarioTermoConsentimentoService.getValidarTermoConsentimento().pipe(
      map((r) => {
        if (r.flExibirTermo) {
          this.routerService.toTermoConsentimento();
        }
        return !r.flExibirTermo;
      })
    );
  }
}
