<nav class="bottom-bar-container">
  @for (menu of sidebarItems; track menu) {
    <div *appHasPermission="menu.permission" [attr.data-tutorial]="menu.dataTutorial">
      <a
        class="link"
        [routerLink]="menu.router ? [menu.router] : []"
        [routerLinkActive]="menu.router ? 'active' : ''"
        (click)="onLinkClick(menu)"
        >
        <span class="material-icons"> {{ menu.icon }} </span>
        <span> {{ menu.nome }} </span>
      </a>
    </div>
  }
</nav>
