<div class="layout-wrapper" [ngClass]="{'layout-wrapper-menu-active': sidebarActive, 'layout-overlay-menu': overlay}">
  <app-topbar></app-topbar>
  <app-sidebar (submenuActiveChange)="submenuActive = $event"></app-sidebar>
  <div class="layout-main" id="layout-main">
    <div class="layout-main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-bottombar></app-bottombar>
</div>
