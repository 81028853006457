import { Component, Input, ViewChild } from "@angular/core";
import { SidebarService } from "../sidebar/sidebar.service";
import { OverlayPanel } from "primeng/overlaypanel";
import { NgForm } from "@angular/forms";
import { UsuarioService } from "src/app/shared/api-services/usuario.service";
import { RequestUsuarioPerfilTO } from "src/app/shared/tos/request-usuario-perfil.to";
import { Util } from "src/app/shared/services/util.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: "app-editar-dados-popup",
  templateUrl: "./editar-dados-popup.component.html",
  styleUrl: "./editar-dados-popup.component.scss",
})
export class EditarDadosPopupComponent {
  @ViewChild(NgForm) f: NgForm;
  @ViewChild(OverlayPanel) overlayPanelComponent: OverlayPanel;
  @Input() nmIniciais: string;
  @Input() nmCompleto: string;

  show: boolean = false;
  loadingEdit: boolean = false;
  loadingDados: boolean = false;
  showPopupEdicao: boolean = false;

  itensMenu = [
    {
      icon: "assets/images/sidebar/usuario.svg",
      label: "editarDados.dsNome",
      tooltip: "editarDados.editarNome",
    },
    {
      icon: "assets/images/sidebar/envelope.svg",
      label: "editarDados.dsEmail",
      tooltip: "editarDados.editarEmail",
    },
    {
      icon: "assets/images/sidebar/telefone.svg",
      label: "editarDados.dsCelular",
      tooltip: "editarDados.editarTelefone",
    },
  ];

  constructor(
    private sidebarService: SidebarService,
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private authService: AuthService
  ) {}

  toggle(ev) {
    if (ev) {
      this.overlayPanelComponent.toggle(ev);
      this.getDadosUsuario();
    } else {
      this.show = !this.show;
      this.sidebarService.travarScroll(this.show);
      if (this.show) {
        this.getDadosUsuario();
      } else {
        this.closePopup();
      }
    }
  }

  closePopup() {
    this.showPopupEdicao = false;
    this.show = false;
    this.sidebarService.travarScroll(this.show);
  }

  getDadosUsuario() {
    this.loadingDados = true;
    this.usuarioService.getPerfilUsuarioLogado().subscribe(
      (r) => {
        this.loadingDados = false;
        this.itensMenu[0].label = r.dsNome || "editarDados.dsNome";
        this.itensMenu[1].label = r.dsEmail || "editarDados.dsEmail";
        this.itensMenu[2].label = r.dsCelular || "editarDados.dsCelular";
        this.f.form.patchValue(r);
      },
      (err) => {
        this.loadingDados = false;
        throw err;
      }
    );
  }

  handleOnClickEdit() {
    this.overlayPanelComponent.hide();
    this.showPopupEdicao = true;
  }

  saveEdit(f: NgForm) {
    if (f.valid) {
      const request: RequestUsuarioPerfilTO = Util.cleanNullValues(f.value);
      this.loadingEdit = true;
      this.usuarioService.atualizarPerfilUsuarioLogado(request).subscribe(
        (_) => {
          this.loadingEdit = false;
          this.closePopup();
          this.getDadosUsuario();
          this.toastService.notifyWithTranslateSuccess("salvoSucesso");
        },
        (err) => {
          this.loadingEdit = false;
          throw err;
        }
      );
    }
  }

  logout() {
    this.authService.logout();
  }
}
