import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { HierarquiaState } from "../../states/hierarquia.state";
import { HierarquiaService } from "../../api-services/hierarquia.service";

@Injectable({
  providedIn: "root",
})
export class ConfigLoadedGuard {
  systemLoaded = false;

  constructor(private hierarquiaState: HierarquiaState, private hierarquiaService: HierarquiaService) {}

  canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.systemLoaded) {
      return true;
    }
    this.hierarquiaService.getAllIdsHierarquiasTemporariasFromAPI();
    return this.hierarquiaState.getHierarquias().pipe(
      map((r) => r?.length > 0),
      tap((_) => (this.systemLoaded = true))
    );
  }
}
