import { KeycloakService } from "keycloak-angular";

import { environment } from "../environments/environment";
import { initNewRelic } from "./initNewRelic";

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    initNewRelic();
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init(environment.keycloakInit);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}
