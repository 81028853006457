import { NgModule, Optional, SkipSelf } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { LayoutComponent } from "./layout.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SidebarTabContentComponent } from "./sidebar/sidebar-tab-content.component";
import { MenuComponent } from "./menu/menu.component";
import { SubmenuComponent } from "./menu/submenu.component";
import { BottombarComponent } from "./bottombar/bottombar.component";
import { EditarDadosPopupComponent } from "./editar-dados-popup/editar-dados-popup.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    LayoutComponent,
    TopbarComponent,
    SidebarComponent,
    SidebarTabContentComponent,
    MenuComponent,
    SubmenuComponent,
    BottombarComponent,
    EditarDadosPopupComponent,
  ],
})
export class LayoutModule {
  constructor(@Optional() @SkipSelf() parentModule: LayoutModule) {
    if (parentModule) {
      throw new Error(`LayoutModule já está importado. Importe o LayoutModule apenas no AppModule.`);
    }
  }
}
