import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SidebarService } from "../sidebar/sidebar.service";
import { RoleService } from "src/app/shared/services/role.service";

@Component({
  selector: "app-bottombar",
  templateUrl: "./bottombar.component.html",
  styleUrls: ["./bottombar.component.scss"],
})
export class BottombarComponent implements OnInit {
  sidebarItems: any[];

  constructor(private sidebarService: SidebarService, private translateService: TranslateService) {}

  ngOnInit() {
    this.constroiMenus();
  }

  constroiMenus() {
    this.sidebarItems = [
      {
        icon: "exit_to_app",
        material: "true",
        logout: true,
        nome: this.translateService.instant("tituloSidebar.sair"),
        permission: ["ALL"],
      },
      {
        icon: "format_list_bulleted",
        material: "true",
        nome: this.translateService.instant("tituloSidebar.graficos"),
        dataTutorial: "menu-dashboard-mobile",
        router: "/dashboard",
        permission: ["RL_VISUALIZAR_DASHBOARD_"],
      },
      {
        icon: "group",
        material: "true",
        nome: this.translateService.instant("tituloSidebar.acoes"),
        dataTutorial: "testes-pendente-list-mobile",
        router: "/testes",
        permission: [RoleService.ACAO_DEFINIDA_REGEX],
      },
    ];
  }

  onLinkClick(menu) {
    if (menu.logout) {
      this.sidebarService.logout();
    }
  }
}
