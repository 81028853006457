import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";

import { Observable } from "rxjs";

import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class UrlLocaleInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.includes("prontos-temporario.s3") ||
      req.url.includes("prontos-temporario-dev-hmg.s3") ||
      req.url.includes("s3.amazonaws.com") ||
      req.url.includes("amazonaws.com")
    ) {
      return next.handle(req);
    }

    // console.log('currentLang::', this.translateService.currentLang);
    const baseLang = this.translateService.currentLang.split("-")[0];
    const urlReq = req.clone({
      headers: req.headers
        .append("Content-Language", baseLang)
        .append("timezone-offset", new Date().getTimezoneOffset() + ""),
    });
    return next.handle(urlReq);
  }
}
