import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiServicesVersionConfig } from 'src/app/api-services-version.config';

import { TipoDispositivo } from '../enums/tipo-dispositivo.enum';
import { IsMobileService } from '../services/is-mobile.service';
import { SessionStorageService } from '../services/session-storage.service';
import { ConfiguracaoTesteTO } from '../tos/configuracao-teste.to';
import { ValidarTO } from '../tos/validar.to';
import { ApiService } from './api-service.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoTesteApiService implements ApiService {

  configuracao: ConfiguracaoTesteTO;

  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    private isMobileService: IsMobileService,
  ) {
    this.recuperarDaSessao();
  }

  getApiVersion(): string {
    return ApiServicesVersionConfig.configuracaoTeste;
  }

  getConfiguracaoTesteTO(): Observable<ConfiguracaoTesteTO> {

    const validarTO = new ValidarTO();
    const isMobile = this.isMobileService.isMobile();

    validarTO.tipoDispositivo = isMobile ? TipoDispositivo.MOBILE : TipoDispositivo.BROWSER;

    return this.httpClient.post<ConfiguracaoTesteTO>(

      `${this.getApiVersion()}/teste-executado/validar`, validarTO

    ).pipe(tap(obj => this.salvarNaSessao(obj)));
  }

  salvarNaSessao(configuracao: ConfiguracaoTesteTO) {
    this.configuracao = configuracao;
    this.sessionStorageService.setItem('configuracao', configuracao);
  }

  recuperarDaSessao() {
    this.configuracao = this.sessionStorageService.getItem('configuracao');
  }
}
